<template>
  <div class="section-game">
    <div class="section-game__container section-game__container--quayso">
      <SectionTitle
        icon-src="/assets/images/home/icon-quay-so.svg"
        title="Quay số"
        description="Quay liền tay nhận ngay thưởng lớn"
        is-see-more
        :link="LOBBY_GAME_URLS.QUAY_SO"
      />
      <div class="line" />
      <div class="games">
        <template v-for="(item, index) in gameList.slice(0, 4)" :key="index">
          <CommonGamesItemGame class="item-game" :item="item" radius="4" :hidden-jac-pot="true" />
        </template>
      </div>
    </div>
    <div class="section-game__container">
      <SectionGameBai />
    </div>
  </div>
</template>
<script setup lang="ts">
import queryString from 'query-string'
import SectionGameBai from '~/components/desktop/pages/home/section-game-bai/index.vue'
import CommonGamesItemGame from '~/components/common/game-item.vue'
import SectionTitle from '~/components/common/section-title.vue'
import { useGame } from '~~/composables/game/useGame'
import { LOBBY_GAME_URLS } from '~/config/page-url'
const { fetchGames } = useGame()
const fetchGameQuaySo = async () => {
  try {
    const queryUrl = queryString.stringify({
      path_alias: 'quay-so',
      provider_code: '',
      limit: 4,
      page: 1
    })
    const { data: response } = await fetchGames(queryUrl)
    if (response.status === 'OK') {
      return response.data?.items
    }
    return []
  } catch (error) {
    console.error('fetchGameQuaySo', error)
    return []
  }
}
const { data: gameList } = await useAsyncData('fetchGameQuaySo', fetchGameQuaySo)
</script>
<style lang="scss" scoped src="public/assets/scss/components/desktop/pages/home/section-game/index.scss"></style>
